@import '../../features/theme/styles/mixins.scss';

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin-top: 16px;
    font-size: 24px;
    text-align: center;
    max-width: 420px;
    line-height: 35px;
    padding: 0px 20px;

    @include mobile {
      max-width: 100%;
    }
  }

  > p {
    margin: 16px 0px;
    text-align: center;
    width: 370px;
    padding: 0px 20px;

    @include mobile {
      max-width: 100%;
    }
  }

  .footer {
    display: flex;

    button {
      padding: 10px;
    }
  }
}
